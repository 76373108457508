<template>
  <!-- eslint-disable vue/no-v-html -->
  <div v-if="loaded">
    <SeperationBox>
      <template>
        {{ $t(i18nName) }}
      </template>
    </SeperationBox>
    <div class="bg-interface cms-content">
      <div v-html="translation" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SeperationBox } from '../../components';

export default {
  components: {
    SeperationBox,
  },

  data() {
    return {
      data: null,
      loaded: false,
    };
  },

  computed: {
    ...mapGetters(['currentUser']),
    cmsName() {
      return this.$route.params.slug.replace('-', '_');
    },
    i18nName() {
      const i = this.$route.params.slug.indexOf('-');
      if (i > 0) {
        const firstWord = this.$route.params.slug.slice(0, i);
        let secondWord = this.$route.params.slug.slice(i + 1);
        secondWord = secondWord.charAt(0).toUpperCase() + secondWord.slice(1);
        return firstWord + secondWord;
      }
      return this.$route.params.slug;
    },
    translation() {
      if (this.data) {
        let translation = this.data.translations.find((translation) =>
          translation.languages_code.startsWith(this.currentUser.language),
        ).content;
        if (!translation) {
          translation = this.data.translations[0].content;
        }
        return translation;
      }

      return this.data;
    },
  },

  watch: {
    $route: {
      deep: true,
      immediate: true,
      async handler() {
        this.loaded = false;

        if (this.cmsName === 'agb') {
          const resp = await this.$axios(
            // eslint-disable-next-line max-len
            `${process.env.VUE_APP_DIRECTUS_URL}/items/${this.cmsName}?fields=*,translations.*&filter[active]=true`,
          );
          [this.data] = resp.data.data;
        } else {
          const resp = await this.$axios(
            // eslint-disable-next-line max-len
            `${process.env.VUE_APP_DIRECTUS_URL}/items/${this.cmsName}?fields=*,translations.*`,
          );
          this.data = resp.data.data;
        }

        this.loaded = true;
      },
    },
  },
};
</script>
