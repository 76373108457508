<template>
  <div>
    <AuthLogin v-if="mode === 'login'" @changeMode="mode = $event" />
    <AuthRegistration v-if="mode === 'register'" @changeMode="mode = $event" />
    <AuthPasswortReset v-if="mode === 'resetPassword'" @changeMode="mode = $event" />
    <SitePreview v-if="mode === 'sitePreview'" @changeMode="mode = $event" />
  </div>
</template>

<script>
import Vue from 'vue';
import { AuthLogin, AuthRegistration, AuthPasswortReset, SitePreview } from '../components';

export default Vue.extend({
  components: {
    AuthLogin,
    AuthRegistration,
    AuthPasswortReset,
    SitePreview,
  },

  data: () => ({
    mode: 'login',
  }),
});
</script>
